import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'

const ConsultantPageTemplate = ({ title, notice, board, consultants, content, disclosure, activeConflicts, contentComponent }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-7 is-offset-1">
            <div className="section">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <Notice notice={notice} />

              <h2 className="is-size-3">Board of Directors</h2>
              <hr />
              {board.map(director => (
                <div key={director.name}>
                {/* <div className="card" style={{ marginBottom: '1rem'}}> */}
                  {/* <div className="card-content"> */}
                    <p  className="is-size-5">{director.name}<br /><small className="has-text-grey">{director.role} - <span style={{ fontSize: '0.8rem' }}>Term ends: {director.term}</span></small></p>
                    <p>Business Phone: (713) 623-4531<br />Fax:	(713) 623-6143<br />
                    Mailing Address: 1300 Post Oak Blvd., Ste. 1400, Houston, TX 77056</p>
                    <hr />
                  {/* </div> */}
                {/* </div> */}
                </div>
              ))}

              <h3 className="is-size-3">Conflicts Disclosure Statements:</h3>
              {(activeConflicts) 
              ? <p><a href={disclosure.publicURL} className="button">View disclosure statement(s)</a></p> 
              : <p>No conflicts to report for the current Board of Directors.</p> }


              <PageContent className="content" content={content} />


            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
              <h2 className="is-size-3">Consultants and Vendors</h2>
              <hr/>
              <div className="columns is-multiline">
                {consultants.map(consultant => {
                  function Website({consultant}) {
                    if(consultant.website !== null)
                    {
                      return (< ><br/><a href={consultant.website}>Website</a></ >)
                    }
                    else { return (< ></ >)}
                  }
                  return (
                    <div key={consultant.company} className="column is-4">
                      <div className="card">
                        <div className="card-header">
                          <div className="card-header-title">
                            {consultant.role}
                          </div>
                        </div>
                        <div className="card-content">
                          <p>{consultant.company}<br/>
                          {consultant.address1}<br/>
                          {consultant.address2}
                          <Website consultant={consultant} />
                        </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ConsultantPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default ConsultantPageTemplate
